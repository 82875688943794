<template>
	<div class="c-form__banner">
		<div class="o-row o-row--sm">
			<div class="o-container o-container--lg">
				<div class="c-form__banner--bg" />
			</div>
		</div>
	</div>
	<div class="c-form__info">
		<div class="grid">
			<div class="row">
				<h1 class="c-form__info--title">Voeg automaat toe</h1>
				<p class="c-form__info--description">
					Hier kun je een verkoopautomaat toevoegen aan het platform of een bestaande automaat aanpassen. Je
					kunt automaten toevoegen, zelfs als je niet de eigenaar bent. Op deze manier help je andere mensen
					om automaten in hun buurt te vinden.
				</p>
				<!-- <strong>{{ form }}</strong> -->
			</div>
		</div>
	</div>
	<div v-if="!pageLoading" class="o-row">
		<div class="o-container">
			<div class="c-form">
				<form method="POST" action="" @submit.prevent="saveMachine()">
					<div class="grid">
						<div class="row">
							<div class="col-lg-6">
								<p class="c-form__title">Informatie</p>

								<div class="grid">
									<div class="row">
										<div class="c-input">
											<label class="c-label" for="">Locatie</label>
											<Multiselect
												v-model="form.location_id"
												track-by="name"
												label="name"
												value-prop="location_id"
												placeholder="Locatie"
												:options="locations"
												:show-labels="false"
												:allow-empty="false"
												:internal-search="true"
												:clear-on-select="false"
												:close-on-select="true"
											/>
										</div>
										<div class="c-input">
											<label class="c-label" for="">Openingsuren</label>
											<Multiselect
												v-model="form.opening_time_id"
												track-by="name"
												label="name"
												value-prop="opening_time_id"
												placeholder="Openingsuren"
												:options="opening_times"
												:show-labels="false"
												:allow-empty="false"
												:internal-search="true"
												:clear-on-select="false"
												:close-on-select="true"
											/>
										</div>
										<div class="c-input c-input--radio">
											<label class="c-label" for="Hoofdcategorie"> Hoofdcategorie </label>
											<p class="c-input__info">
												De geselecteerde categorie zal ook het icoon bepalen van de automaat.
											</p>
											<div class="grid">
												<div class="row">
													<div
														v-for="category in categories"
														:key="category.category_id"
														class="col-md-6"
													>
														<div class="c-radio">
															<input
																:id="category.category_id"
																v-model="form.main_category_id"
																:value="category.category_id"
																type="radio"
																name="Hoofdcategorie"
																v-bind="$attrs"
															/>
															<label class="c-radio__button" :for="category.category_id">
																<div class="c-radio__button--icon">
																	<div class="c-radio__button--icon-indicator" />
																</div>
																<span class="c-radio__button--label">{{
																	category.name
																}}</span>
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="a-input__checkbox-list">
									<label class="c-label" for="Extracategorie"> Extra categorieën (optioneel) </label>
									<div class="a-input__checkbox-list--items">
										<div class="grid">
											<div class="row">
												<div
													v-for="category in categories"
													:key="category.category_id"
													class="col-md-6"
												>
													<label
														:key="category.category_id"
														class="a-input__checkbox-container"
													>
														{{ category.name }}
														<span v-if="category.category_id == form.main_category_id"
															><img
																class="c-form__main-category"
																src="@/assets/images/icons/star.svg"
																alt=""
														/></span>

														<input
															:value="category.category_id"
															:disabled="category.category_id == form.main_category_id"
															:checked="shouldCategoryBeChecked(category.category_id)"
															type="checkbox"
															@change="categoryChecked($event, category.category_id)"
														/>
														<span class="a-input__checkbox-checkmark" />
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- ------------- -->
								<div class="a-input__checkbox-list">
									<label class="c-label" for="Extracategorie"> Betalingsmogelijkheden </label>
									<div class="a-input__checkbox-list--items">
										<div class="grid">
											<div class="row">
												<div
													v-for="method in paymentMethods"
													:key="method.payment_method_id"
													class="col-md-6"
												>
													<label
														:key="method.payment_method_id"
														class="a-input__checkbox-container"
													>
														{{ method.name }}
														<input
															type="checkbox"
															:checked="
																shouldPaymentMethodBeChecked(method.payment_method_id)
															"
															@change="
																paymentMethodChecked($event, method.payment_method_id)
															"
														/>
														<span class="a-input__checkbox-checkmark" />
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- ------------- -->
								<!-- BEGIN PREMIUM FIELDS -->
								<div v-if="false" class="c-form__premium">
									<p class="c-form__title">Gedetailleerde informatie (premium)</p>
									<div>
										<input
											id="is_premium"
											v-model="form.is_premium"
											type="checkbox"
											name="is_premium"
										/>
										<label for="is_premium">
											Ik ben eigenaar van deze automaat en wil deze premium maken.</label
										>
									</div>
									<p>
										Hiervoor zal één van uw <strong class="u-text-primary">3</strong> tokens
										gebruikt worden.
									</p>
									<InputComponent v-model="form.name" label="Naam" />
									<div class="c-input">
										<label class="c-label" for="description">Beschrijving</label>
										<textarea id="description" v-model="form.description" rows="4" />
									</div>
									<InputComponent v-model="form.website" label="Website" />
									<div>
										<label class="c-label" for="Hoofdcategorie"> Openingsuren </label>
										<OpeningTimes />
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-11">
								<p class="c-form__title">Locatie</p>
								<div class="grid">
									<div class="row">
										<div class="col-md-9">
											<InputComponent v-model="form.address.street" label="Straat" />
										</div>
										<div class="col-md-3">
											<InputComponent v-model="form.address.number" label="Nr" />
										</div>
										<div class="col-md-9">
											<InputComponent v-model="form.address.city" label="Gemeente" />
										</div>
										<div class="col-md-3">
											<InputComponent v-model="form.address.zip_code" label="Postcode" />
										</div>
									</div>
									<div class="row">
										<div class="c-input">
											<label class="c-label" for="">Locatie:</label>
											<p class="c-input__info">
												Klik op de kaart voor het pijltje op de exacte locatie van de
												verkoopsautomaat te plaatsen.
											</p>
										</div>
										<div class="c-overview__map">
											<PinPointMap :existing-marker="existingMarker" @markerPlaced="newLatLong" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-6">
								<div class="grid">
									<div class="row">
										<div class="col-lg-6">
											<Button :loading="formLoading" type="submit"> Opslaan </Button>
										</div>
										<div class="col-lg-6">
											<button
												class="c-button c-button--secondary"
												type="button"
												@click="$router.go(-1)"
											>
												Annuleren
											</button>
										</div>
									</div>
									<div class="row">
										<ul v-for="error in formErrors" :key="error">
											<li>{{ error }}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script src="./MachineForm.controller.js"></script>
<style lang="scss" src="./MachineForm.styles.scss"></style>
