import { InputComponent, PinPointMap, RadioButtonComponent, OpeningTimes, Button } from "@/components";
import Multiselect from "@vueform/multiselect";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
	name: "MachineForm",
	components: {
		InputComponent,
		RadioButtonComponent,
		PinPointMap,
		OpeningTimes,
		Multiselect,
		Button,
	},
	data: () => ({
		form: {
			name: "",
			description: "",
			location_id: "", // ENUM --> "indoor", "outdoor"
			main_category_id: null,
			categories: [],
			payment_methods: [],
			opening_time_id: "", // ENUM --> "day", "night", "24h"
			website: "",
			address: {
				country: "BE",
				street: "",
				number: "",
				city: "",
				zip_code: "",
			},
			coordinates: {
				longitude: 0,
				latitude: 0,
			},
			opening_hours: [],
			out_of_use: false,
			is_premium: false,
		},
		pageLoading: true,
		existingMarker: null,
		// locations: ["indoor", "outdoor"],
		// locations: [1, 2],
		// opening_times: ["day", "night", "24h"],
		// opening_times: [1, 2, 3],
		formErrors: [],
	}),
	watch: {
		form: {
			handler() {
				console.log("Form is veranderd!");
			},
			deep: true,
		},
		$route: {
			handler() {
				if (this.$route.path.includes("/machine")) {
					location.reload();
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters("machine", {
			categories: "getCategories",
			paymentMethods: "getPaymentMethods",
			locations: "getLocations",
			opening_times: "getOpeningTimes",
			formLoading: "getLoadingStateMachineForm",
			machine: "getMachine",
		}),
	},
	methods: {
		...mapActions("machine", {
			fetchPaymentMethods: "FETCH_PAYMENT_METHODS",
			fetchLocations: "FETCH_LOCATIONS",
			fetchOpeningTimes: "FETCH_OPENING_TIMES",
			createMachine: "CREATE_MACHINE",
			updateMachine: "UPDATE_MACHINE",
			fetchMachine: "FETCH_DATA_MACHINE",
		}),
		newLatLong(newLatLong) {
			[this.form.coordinates.latitude, this.form.coordinates.longitude] = newLatLong;
		},
		saveMachine() {
			this.validateForm();

			if (this.formErrors.length == 0) {
				if (this.$route.name == "Machine-edit" && this.$route.params.id) {
					// --- UPDATE ---
					this.updateMachine(this.form).then((response) => {
						// When update was successful, push to detail page
						this.$router.push({ name: "Detail", params: { id: response.vending_machine_id } });
					});
				} else {
					// --- CREATE ---
					// remove main category form categories:
					this.form.categories = this.form.categories.filter((c) => c != this.form.main_category_id);
					this.createMachine(this.form).then((response) => {
						// When create was successful, push to detail page
						this.$router.push({ name: "Detail", params: { id: response.vending_machine_id } });
					});
				}
			} else {
				console.log("Form contains errors");
			}
		},
		mainCategoryChanged(categoryId) {
			// If category was in (other categories) array, remove:
			if (this.form.categories.findIndex((f) => f === categoryId) !== -1) {
				this.form.categories.splice(
					this.form.categories.findIndex((f) => f === categoryId),
					1
				);
			}
		},
		categoryChecked(state, categoryId) {
			// Add to array
			if (state.target.checked) {
				this.form.categories.push(categoryId);
			}

			// remove from array
			if (!state.target.checked) {
				this.form.categories.splice(
					this.form.categories.findIndex((f) => f === categoryId),
					1
				);
			}
		},
		paymentMethodChecked(state, methodId) {
			// Add to array
			if (state.target.checked) {
				this.form.payment_methods.push(methodId);
			}

			// remove from array
			if (!state.target.checked) {
				this.form.payment_methods.splice(
					this.form.payment_methods.findIndex((f) => f === methodId),
					1
				);
			}
		},
		validateForm() {
			const errors = [];

			if (!this.form.location_id) errors.push("Please select a valid location");
			if (!this.form.opening_time_id) errors.push("Please select a valid opening time");
			if (!this.form.address.street) errors.push("Please provide a valid street");
			if (!this.form.address.number) errors.push("Please provide a valid number");
			if (!this.form.address.city) errors.push("Please provide a valid city");
			if (!this.form.address.zip_code) errors.push("Please provide a valid zip code");
			if (!this.form.main_category_id) errors.push("Please provide a valid main category");
			if (!this.form.payment_methods || this.form.payment_methods.length < 1)
				errors.push("Please provide at least one valid payment method");
			if (!this.form.coordinates.latitude || !this.form.coordinates.longitude)
				errors.push("Please provide a valid marker (location) on the map");

			if (this.form.is_premium) {
				if (!this.form.name) errors.push("Please provide a valid name");
				if (!this.form.description) errors.push("Please provide a valid description");
				if (!this.form.website) errors.push("Please provide a valid website");
			}

			this.formErrors = errors;
		},
		loadEditData() {
			if (this.$route.name == "Machine-edit" && this.$route.params.id) {
				this.fetchMachine(this.$route.params.id).then(() => {
					// Put ecisting machine data inside form object
					this.form = _.cloneDeep(this.machine);

					// Map location_id:
					this.form.location_id = this.form?.location?.location_id ?? null;
					this.form.location = this.form?.location?.name ?? null;

					// Map opening_time_id:
					this.form.opening_time_id = this.form?.opening_time?.opening_time_id ?? null;

					// Set exisiting marker on map:
					this.existingMarker = {
						latLong: [this.form.coordinates.latitude, this.form.coordinates.longitude],
					};
					// Set categories:
					this.form.categories = this.form.categories.map((c) => c.category_id);
					this.form.main_category_id = this.form?.main_category?.category_id ?? null;
					this.form?.main_category?.category_id ?? null;

					// Set paymentMethods:
					this.form.payment_methods = this.form.payment_methods.map((p) => p.payment_method_id);

					this.pageLoading = false;
				});
			} else {
				this.pageLoading = false;
			}
		},
		shouldCategoryBeChecked(category_id) {
			if (
				category_id == this.form.main_category_id ||
				(this.form.categories && this.form.categories.includes(category_id))
			) {
				return true;
			}
		},
		shouldPaymentMethodBeChecked(methodId) {
			if (this.form.payment_methods && this.form.payment_methods.includes(methodId)) {
				return true;
			}
		},
	},
	created() {
		this.fetchPaymentMethods();
		this.fetchOpeningTimes();
		this.fetchLocations();
		this.loadEditData();
	},
};
